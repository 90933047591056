import _ from 'lodash';

const toSnakeCase = (object: any): Record<string, any> => {
  if (Array.isArray(object)) {
    return object.map((v) => toSnakeCase(v));
  } else if (object != null && object.constructor === Object) {
    return Object.keys(object).reduce(
      (result, key) => ({
        ...result,
        [_.snakeCase(key)]: toSnakeCase(object[key]),
      }),
      {}
    );
  }
  return object;
};

export default toSnakeCase;
