import { Integration } from '@/models';
import httpClient from './httpClient';

const getAllAvailableContentPermissions = () => httpClient.get(`/permissions`);

const getAllIntegrations = () => httpClient.get(`/integrations`);

const getIntegrationPermissions = (integrationId: string) =>
  httpClient.get(`/integration/${integrationId}/permissions`);

const updateIntegrationPermission = (
  permissionId: string,
  enabledValue: boolean
) => httpClient.put(`/permission/${permissionId}`, { enabled: enabledValue });

const updateIntegration = async (
  integrationId: string,
  data: {
    displayName: string;
  }
): Promise<Integration> =>
  (await httpClient.patch(`/integration/${integrationId}`, data))
    .data as Integration;

export {
  getAllAvailableContentPermissions,
  getAllIntegrations,
  getIntegrationPermissions,
  updateIntegrationPermission,
  updateIntegration,
};
