import httpClient from './httpClient';

interface userModificationData {
  email: string;
  password: string;
  roles: string[];
}

const getUsers = (page?: number, pageSize?: number) => {
  const params = {
    page,
    pageSize,
  };
  return httpClient.get('/users', {
    params,
  });
};

const getUser = (id: string) => httpClient.get(`/users/${id}`);

const getSelfUser = () => httpClient.get('/users/self');

const getPathAllowed = (path: string) =>
  httpClient.get(`/users/self/allowed_path`, { params: { path } });

const createUser = (userData: userModificationData) =>
  httpClient.post('/users', userData);

const updateUser = (id: string, userData: userModificationData) =>
  httpClient.put(`/users/${id}`, userData);

const deleteUser = (id: string) => httpClient.delete(`/users/${id}`);

const changeUserPassword = () => httpClient.get('/user/change-password');

export {
  getUsers,
  getUser,
  getSelfUser,
  getPathAllowed,
  createUser,
  updateUser,
  deleteUser,
  changeUserPassword,
};
