
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue';
import { useRouter } from 'vue-router';
import { MenuIcon, XIcon, SelectorIcon } from '@heroicons/vue/outline';
import { CheckIcon, UserCircleIcon } from '@heroicons/vue/solid';
import { ref, onMounted, watch } from 'vue';
import { computed, defineComponent, inject } from '@vue/runtime-core';
import store from '@/store';
import { AuthKey } from '@/symbols';
import { getTripsBySearch } from '@/api/trips.api';
import { Session, Trip } from '@/models';

// eslint-disable-next-line
const REPORT_RESTRICTED_USERS = ['travelperk'];

interface SearchResult {
  item: Trip | Session;
  resultType: 'trip' | 'session';
}

const navigation = [
  { name: 'Dashboard', href: '/dashboard', current: true },
  { name: 'Trip Explorer', href: '/trips' },
  { name: 'Organization', href: '/organization' },
  {
    name: 'User Management',
    href: '/users',
    validation_func: (user: Record<string, unknown>) =>
      (user?.roles as string[])?.includes('user_admin'),
  },
  {
    name: 'Post-Booking White Label', // Needs a shorter name
    href: '/whitelabel-postbooking',
    validation_func: (user: Record<string, unknown>) =>
      (user?.roles as string[])?.includes('white_label'),
  },
  {
    name: 'Billing',
    href: '/billing',
    validation_func: (user: Record<string, unknown>) =>
      (user?.roles as string[])?.includes('finance'),
  },
  { name: 'Support', href: '/support' },
];

const userNavigation = [
  { name: 'Change Password', href: '/change-password', euEnabled: false },
];

export default defineComponent({
  name: 'DashboardNav',
  components: {
    CheckIcon,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    MenuIcon,
    XIcon,
    UserCircleIcon,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    SelectorIcon,
  },
  inject: ['Auth'],
  methods: {
    logout: function (): void {
      // eslint-disable-next-line
      (this as any).Auth.logout();
      this.$router.push({ path: '/' });
    },
  },

  setup() {
    const router = useRouter();
    const auth = inject('Auth') as typeof AuthKey;

    const searchQuery = ref('');
    const searchResults = ref([] as SearchResult[]);
    const searchedTrips = ref([] as Trip[]);
    const searchedSessions = ref([] as Session[]);
    const searchLoading = ref(false);

    const error = ref('');
    const sharedState = store.state;
    const showReports = ref(
      sharedState.integration?.organization?.name === 'travelperk'
    );
    const user = sharedState.user;
    const euEnv = process.env.VUE_APP_EU_ENV;

    const filteredNavigation = computed(() =>
      navigation.filter((item) =>
        item?.validation_func ? item.validation_func(user) : true
      )
    );

    const filteredUserNavigation = computed(() =>
      userNavigation.filter((item) => item.euEnabled || !euEnv)
    );

    const searchResultsRef = ref(null);

    const search = async () => {
      try {
        searchLoading.value = true;
        clearSearchResults();
        if (searchQuery.value.length) {
          searchResults.value = (
            await getTripsBySearch(searchQuery.value)
          ).data.results;
          searchedTrips.value = searchResults.value
            .filter((searchResult) => searchResult.resultType === 'trip')
            .map((searchResult) => searchResult.item) as Trip[];
          searchedSessions.value = searchResults.value
            .filter((searchResult) => searchResult.resultType === 'session')
            .map((searchResult) => searchResult.item) as Session[];
        }
      } catch (e) {
        error.value = 'Error searching for results';
      } finally {
        searchLoading.value = false;
      }
    };

    const clearSearchResults = () => {
      searchResults.value = [];
      searchedTrips.value = [];
      searchedSessions.value = [];
      error.value = '';
    };

    const goToTrip = (trip_id: string) => {
      clearSearchResults();
      router.push({ path: `/trip/${trip_id}` });
    };

    const goToSession = (session_id: number) => {
      clearSearchResults();
      router.push({ path: `/session/${session_id}` });
    };

    onMounted(() => {
      // Needed for us to remove the bar on click (either external or internal)
      window.addEventListener('click', (_ev: MouseEvent) => {
        if (
          searchResults.value.length > 0 ||
          error.value ||
          searchLoading.value
        )
          clearSearchResults();
      });
    });

    return {
      ...auth,
      user,
      navigation: filteredNavigation,
      userNavigation: filteredUserNavigation,
      searchQuery,
      searchResults,
      searchedTrips,
      searchedSessions,
      sharedState,
      showReports,
      search,
      goToTrip,
      goToSession,
      searchLoading,
      error,
      searchResultsRef,
    };
  },
});
