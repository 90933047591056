import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { Auth0 } from '@/auth';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    redirect: '/dashboard',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName "login" */ '@/views/Login.vue'),
    meta: {
      layout: 'AppLayoutAuth',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () =>
      import(/* webpackChunkName "dashboard" */ '@/views/Dashboard.vue'),
    meta: {
      layout: 'AppLayoutDashboard',
    },
    beforeEnter: Auth0.routeGuard,
  },
  {
    path: '/support',
    name: 'support',
    component: () =>
      import(/* webpackChunkName "support" */ '@/views/Support.vue'),
    meta: {
      layout: 'AppLayoutDashboard',
    },
    beforeEnter: Auth0.routeGuard,
  },
  {
    path: '/support/content-availability',
    name: 'support-content-availability',
    component: () =>
      import(
        /* webpackChunkName "supportcontentavailability" */ '@/views/SupportContentAvailability.vue'
      ),
    meta: {
      layout: 'AppLayoutDashboard',
    },
    beforeEnter: Auth0.routeGuard,
  },
  {
    path: '/trip/:id',
    name: 'trip',
    component: () => import(/* webpackChunkName "trip" */ '@/views/Trip.vue'),
    meta: {
      layout: 'AppLayoutDashboard',
    },
    beforeEnter: Auth0.routeGuard,
  },
  {
    path: '/session/:id',
    name: 'session',
    component: () =>
      import(/* webpackChunkName "session" */ '@/views/Session.vue'),
    meta: {
      layout: 'AppLayoutDashboard',
    },
    beforeEnter: Auth0.routeGuard,
  },
  {
    path: '/trips/:id?',
    name: 'trips',
    component: () => import(/* webpackChunkName "trips" */ '@/views/Trips.vue'),
    meta: {
      layout: 'AppLayoutDashboard',
    },
    beforeEnter: Auth0.routeGuard,
  },
  {
    path: '/organization',
    name: 'organization',
    component: () =>
      import(/* webpackChunkName "organization" */ '@/views/Organization.vue'),
    meta: {
      layout: 'AppLayoutDashboard',
    },
    beforeEnter: Auth0.routeGuard,
    redirect: { name: 'organization-integrations' },
    children: [
      {
        path: 'integrations',
        name: 'organization-integrations',
        component: () =>
          import(
            /* webpackChunkName "organizationintegrations" */ '@/views/OrganizationIntegrations.vue'
          ),
      },
    ],
  },
  {
    path: '/callbacks/create',
    name: 'createCallback',
    component: () =>
      import(/* webpackChunkName "report" */ '@/views/CreateCallback.vue'),
    meta: {
      layout: 'AppLayoutDashboard',
    },
    beforeEnter: Auth0.routeGuard,
  },
  {
    path: '/report',
    name: 'report',
    component: () =>
      import(/* webpackChunkName "report" */ '@/views/Report.vue'),
    meta: {
      layout: 'AppLayoutDashboard',
    },
    beforeEnter: Auth0.routeGuard,
  },
  {
    path: '/users',
    name: 'users',
    component: () =>
      import(/* webpackChunkName "report" */ '@/views/Users.vue'),
    meta: {
      layout: 'AppLayoutDashboard',
    },
    beforeEnter: Auth0.routeGuard,
  },
  {
    path: '/users/add',
    name: 'addUser',
    component: () =>
      import(/* webpackChunkName "report" */ '@/views/AddUser.vue'),
    meta: {
      layout: 'AppLayoutDashboard',
    },
    beforeEnter: Auth0.routeGuard,
  },
  {
    path: '/users/:id',
    name: 'user',
    component: () => import(/* webpackChunkName "report" */ '@/views/User.vue'),
    meta: {
      layout: 'AppLayoutDashboard',
    },
    beforeEnter: Auth0.routeGuard,
  },
  {
    path: '/billing',
    name: 'billing',
    component: () =>
      import(/* webpackChunkName "billing" */ '@/views/Billing.vue'),
    meta: {
      layout: 'AppLayoutDashboard',
    },
    beforeEnter: Auth0.routeGuard,
    redirect: { name: 'billing-invoices' },
    children: [
      {
        path: 'invoices',
        name: 'billing-invoices',
        component: () =>
          import(
            /* webpackChunkName "billinginvoices" */ '@/views/BillingInvoices.vue'
          ),
      },
    ],
  },
  {
    path: '/whitelabel-postbooking',
    name: 'whitelabel-postbooking',
    component: () => import('@/views/WhiteLabelPostBooking.vue'),
    meta: {
      layout: 'AppLayoutDashboard',
    },
    beforeEnter: Auth0.routeGuard,
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () =>
      import(/* webpackChunkName "report" */ '@/views/ChangePassword.vue'),
    meta: {
      layout: 'AppLayoutDashboard',
    },
    beforeEnter: Auth0.routeGuard,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
