import { reactive } from '@vue/reactivity';
import { Integration, OrganizationUser } from '@/models';

const store = {
  state: reactive({
    loading: false,
    integration: {} as Integration,
    integrations: [] as Integration[],
    user: {} as OrganizationUser,
  }),

  setIntegrations(integrations: Integration[]): void {
    this.state.integrations = integrations;
    this.state.integration = integrations[0];
  },

  findIntegrationById(integrationId: string): Integration | undefined {
    return this.state.integrations.find((i) => i.id == integrationId);
  },

  setActiveIntegration(integration: Integration): void {
    this.state.integration = integration;
  },

  setUser(user: OrganizationUser): void {
    this.state.user = user;
  },
};

export default store;
