import _ from 'lodash';

const toCamelCase = (object: any): Record<string, any> => {
  if (Array.isArray(object)) {
    return object.map((v) => toCamelCase(v));
  } else if (object != null && object.constructor === Object) {
    return Object.keys(object).reduce(
      (result, key) => ({
        ...result,
        [_.camelCase(key)]: toCamelCase(object[key]),
      }),
      {}
    );
  }
  return object;
};

export default toCamelCase;
