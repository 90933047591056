import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router';
import AppLayout from '@/layouts/AppLayout.vue';
import { Auth0 } from '@/auth';
import './index.css';

async function init() {
  const AuthPlugin = await Auth0.init({
    onRedirectCallback: (appState) => {
      router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
      );
    },
    clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    connection: process.env.VUE_APP_AUTH0_CONNECTION,
    redirectUri: window.location.origin,
  });

  const app = createApp(App);

  app
    .use(AuthPlugin)
    .use(router)
    .component('AppLayout', AppLayout)
    .mount('#app');
}

init();
