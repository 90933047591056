
import { watchEffect } from 'vue';
import { ref } from '@vue/reactivity';
import store from '@/store';
import { getAllIntegrations } from '@/api/integrations.api';
import { getSelfUser } from '@/api/users.api';
import DashboardNav from '@/components/DashboardNav.vue';
import { authPlugin } from '@/auth/index';

export default {
  name: 'AppLayoutDashboard',
  components: {
    DashboardNav,
  },
  setup(): Record<string, unknown> {
    const loading = ref(false);
    const sharedState = store.state;

    watchEffect(async () => {
      try {
        loading.value = true;
        const integrations = await getAllIntegrations();
        store.setIntegrations(integrations.data.integrations);

        const user = (await getSelfUser()).data;
        store.setUser(user);
        loading.value = false;
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    });

    return {
      loading,
      sharedState,
    };
  },
};
